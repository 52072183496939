import {APP_INITIALIZER, ErrorHandler, forwardRef, NgModule, NgZone, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './home/home.component';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MAT_CARD_CONFIG, MatCardConfig, MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {environment} from '../environments/environment';
import {MatButtonModule} from '@angular/material/button';
import {ExternalLoginComponent} from './external-login/external-login.component';
import {ApiModule} from './api/api.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {GlobalModule} from './modules/global/global.module';
import {AuthModule} from './modules/auth/auth.module';
import {GlobalErrorHandler} from './modules/global/global-error-handler';
import {AuthInterceptor} from './modules/auth/auth.interceptor';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDividerModule} from '@angular/material/divider';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
import {GetStartedComponent} from './get-started/get-started.component';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {SuccessOnboardComponent} from './get-started/success-onboard/success-onboard.component';
import {IConfig, NgxMaskDirective, provideEnvironmentNgxMask} from 'ngx-mask';
import {
  ProcessingPaymentDialogComponent
} from './get-started/processing-payment-dialog/processing-payment-dialog.component';
import {LocationStrategy} from '@angular/common';
import {PathPreserveQueryLocationStrategy} from './modules/global/utils/path-location-strategy';
import {UnsubscribeComponent} from './reminder-unsubscribe/unsubscribe.component';
import {ProviderCallbackComponent} from './provider-callback/provider-callback.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProviderSignupComponent} from './provider-signup/provider-signup.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  TraceInitiativePublicViewComponent
} from './trace-initiative-public-view/trace-initiative-public-view.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {QuillViewHTMLComponent} from 'ngx-quill';
import {Observable, of} from 'rxjs';
import {Amplify} from 'aws-amplify';
import {CookieStorage, Hub} from 'aws-amplify/utils';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
import {AuthService} from './modules/auth/auth.service';
import {CompanyStore} from './modules/portal/store/company-store';
import {QRCodeModule} from 'angularx-qrcode';
import {AuthenticationFlowComponent} from './home/components/authentication-flow/authentication-flow.component';
import {
  SignInStepComponent
} from './home/components/authentication-flow/components/sign-in-step/sign-in-step.component';
import {
  ConfirmWithTotpCodeStepComponent
} from './home/components/authentication-flow/components/confirm-with-totp-code-step/confirm-with-totp-code-step.component';
import {
  NewPasswordStepComponent
} from './home/components/authentication-flow/components/new-password-step/new-password-step.component';
import {
  ChangePasswordWithVerificationCodeStepComponent
} from './home/components/authentication-flow/components/change-password-with-verification-code-step/change-password-with-verification-code-step.component';
import {
  ForgotPasswordStepComponent
} from './home/components/authentication-flow/components/forgot-password-step/forgot-password-step.component';
import {ExternalLogoutComponent} from './external-logout/external-logout.component';
import {MSAL_INSTANCE, MsalModule, MsalService} from '@azure/msal-angular';
import {BrowserCacheLocation, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import {HttpEventEmitterInterceptor} from './modules/global/services/http-event-emitter.interceptor';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

export const AUTH_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => AuthInterceptor),
  multi: true
};

export const HTTP_EVENT_EMITTER_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => HttpEventEmitterInterceptor),
  multi: true
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // 'Application (client) ID' of app registration in the Microsoft Entra admin center - this value is a GUID
      clientId: environment.microsoftClientId,
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
      authority: 'https://login.microsoftonline.com/organizations',
      // Must be the same redirectUri as what was provided in your app registration.
      redirectUri: environment.spaHost,
    }, cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ExternalLoginComponent,
    GetStartedComponent,
    SuccessOnboardComponent,
    ProcessingPaymentDialogComponent,
    UnsubscribeComponent,
    ProviderCallbackComponent,
    ProviderSignupComponent,
    TraceInitiativePublicViewComponent,
    AuthenticationFlowComponent,
    SignInStepComponent,
    ConfirmWithTotpCodeStepComponent,
    NewPasswordStepComponent,
    ChangePasswordWithVerificationCodeStepComponent,
    ForgotPasswordStepComponent,
    ExternalLogoutComponent
  ],
  imports: [
    GlobalModule.forRoot(),
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApiModule.forRoot({rootUrl: environment.apiHost}),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackId, [{
      command: 'config',
      values: [environment.googleAnalyticsTrackId, environment.spaHost != 'https://app.our-trace.com' ? {debug_mode: true} : {}]
    }]),
    FlexModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatDialogModule,
    ExtendedModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatExpansionModule,
    QuillViewHTMLComponent,
    QRCodeModule,
    MsalModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [
        NgZone,
        AuthService,
        CompanyStore
      ],
      multi: true
    },
    AuthInterceptor,
    AUTH_INTERCEPTOR_PROVIDER,
    HttpEventEmitterInterceptor,
    HTTP_EVENT_EMITTER_INTERCEPTOR_PROVIDER,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy},
    provideEnvironmentNgxMask(options),
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {exitAnimationDuration: 0}},
    {provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: {color: 'accent'}},
    {provide: MAT_CARD_CONFIG, useValue: {appearance: 'outlined'} as MatCardConfig},
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

function initializeAppFactory(
  ngZone: NgZone,
  authService: AuthService,
  companyStore: CompanyStore): () => Observable<any> {
  return () => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          ngZone.run(() => {
            authService.navigateToRejectedRoute();
          });
          break;

        case 'signedOut':
          setTimeout(() => {
            companyStore.setCurrentCompany(null);
          }, 500);

          break;

        case 'customOAuthState':
          authService.saveRejectedRoute(data.payload.data);
          break;
      }
    });

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.cognitoUserPoolId,
          userPoolClientId: environment.cognitoUserPoolWebClientId,
          loginWith: {
            oauth: {
              domain: environment.cognitoDomain,
              scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
              redirectSignIn: [environment.cognitoRedirectSignIn],
              redirectSignOut: [environment.cognitoRedirectSignOut],
              responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
          }
        },
      }
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
      domain: environment.cognitoCookieDomain,
      expires: 182,
      sameSite: 'strict',
      secure: environment.cognitoCookieSecure
    }));

    return of(true);
  };
}
