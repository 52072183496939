import {Component} from '@angular/core';
import {TypedFormBuilderService} from '../../../../../modules/global/services/typed-form-builder.service';
import {confirmSignIn, forgetDevice, rememberDevice} from 'aws-amplify/auth';
import {from, switchMap} from 'rxjs';
import {NotificationService} from '../../../../../modules/global/services/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-confirm-with-totp-code-step',
  templateUrl: './confirm-with-totp-code-step.component.html',
  styleUrl: './confirm-with-totp-code-step.component.scss'
})
export class ConfirmWithTotpCodeStepComponent {
  confirmForm = this.formBuilderService.confirmWithTOPTForm();
  verifying = false;

  constructor(
    private notificationService: NotificationService,
    private formBuilderService: TypedFormBuilderService) {
  }

  verify() {
    this.verifying = true;
    const formValue = this.confirmForm.value;

    from(confirmSignIn({
      challengeResponse: formValue.code
    })).pipe(
      switchMap(() => {
        if (formValue.rememberDevice) {
          return from(rememberDevice());
        }
        return from(forgetDevice());
      }),
      finalize(() => this.verifying = false)
    )
      .subscribe({
        next: () => {
          this.notificationService.showSuccess('Code valid - wait to be redirected...');
        },
        error: error => {
          this.notificationService.showError(error.message);
        }
      });
  }

}
