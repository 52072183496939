<div class="flex size-full flex-col items-center justify-center p-2">
  @if (isLoading) {
    <mat-spinner>
    </mat-spinner>
  } @else {
    <mat-card class="w-full md:w-8/12 lg:w-5/12">
      <mat-card-content class="!pb-4">
        @if (error) {
          <h2>{{ error }}</h2>
        }
        @if (authScope === allAuthScopes.Basic) {
          <div>
            <h3>Thanks for your interest</h3>
          </div>
        }
        @if (authScope === allAuthScopes.Full && tenantResults.length > 0) {
          <div class="flex flex-col gap-2">
            <h3>Connection to {{ providerName }} successful 🎉</h3>

            <p>You have successfully authorised <strong>{{ companyName }}'s</strong> connection to
              <strong>{{ providerName }}.</strong> Below are the
              connected
              organisation/s.
            </p>
            <div class="mb-3 flex flex-col items-start gap-4">
              @for (tenant of tenantResults; track tenant.id) {
                <div class="rounded-xl border border-accent-darker px-3 py-1">
                  {{ tenant.name }}
                </div>
              }
            </div>
            <p>If you have a Trace account, you can click the link below to return to the Trace portal. Otherwise, you
              may close this window.</p>

            @if (isPartner) {
              <div class="rounded-2xl border border-amber-700 bg-amber-50 px-8 py-4">
                <h4>Attention ⚠️</h4>
                <p>
                  Your company is part of the Trace Partner program. To connect your organisation to a company, please
                  visit the <strong>Partner Portal.</strong>
                </p>
              </div>
            }

          </div>
        }
      </mat-card-content>
      <mat-card-actions align="end">
        @if (canVisitPartnerPortal) {
          <button mat-flat-button routerLink="/portal/partners" color="primary">Partner Portal</button>
        }
        <button mat-flat-button routerLink="/portal" color="primary">Home</button>
      </mat-card-actions>
    </mat-card>
  }
</div>

